<template>
    <div
        :is="tag || 'div'"
        class="polished card"
        :class="{
            lower,
            elevate: !lower
        }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['lower', 'tag'],
}
</script>
