import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Babka from '~/components/thumbnails/BabkaThumbnail.vue';
import Dials from '~/components/Dials.vue';
import DdButton from '~/components/DdButton.vue';
export default {
  components: {
    Babka: Babka,
    Dials: Dials,
    Dropdown: function Dropdown() {
      return import("~/components/CasesDropdown.vue");
    },
    DdButton: DdButton
  },
  props: ['course', 'defLink'],
  computed: {
    image: function image() {
      if (!this.course.thumbnail) return null;
      return this.course.thumbnail.replace('original', 'medium');
    },
    state: function state() {
      if (this.isFinished) return 'finished';
      if (this.isJoined) return 'joined';
      if (this.isBought) return 'bought';
      return 'none';
    },
    actions: function actions() {
      var actions = [];

      // Certificate
      if (this.state == 'finished' && this.card.certificateId) {
        actions.push({
          link: "/cert/".concat(this.card.certificateId),
          i18n: 'course.certificate',
          icon: 'icon-ic-certificate'
        });
      }

      // To current module
      if (this.state == 'joined' && this.currentModule) {
        actions.push({
          link: this.currentModuleLink,
          i18n: 'course.toCorrentModule',
          icon: 'icon-ic-play-outline'
        });
      }

      // To course
      if (['joined', 'finished'].includes(this.state) && !this.course.awaiting) {
        actions.push({
          link: this.coursePageLink,
          i18n: 'course.toCourse',
          icon: 'icon-ic-graduation-outline'
        });
      }

      // Enroll
      if (this.$auth.user && this.state == 'bought' && !this.course.awaiting) {
        actions.push({
          link: this.coursePageLink,
          i18n: 'course.signup',
          icon: 'icon-ic-flag'
        });
      }

      // About course
      if (this.course.hasLanding) {
        actions.push({
          link: this.courseLandingLink,
          i18n: 'course.moreAboutCourse',
          icon: 'icon-ic-info'
        });
      }
      return actions;
    },
    isFinished: function isFinished() {
      return this.card && this.card.finished;
    },
    isJoined: function isJoined() {
      return this.card && this.card.active;
    },
    isBought: function isBought() {
      return this.course.bought;
    },
    isFree: function isFree() {
      return this.isBought && !this.course.requiredAccess;
    },
    canBuy: function canBuy() {
      return this.course.blocks && this.course.blocks.subscribe;
    },
    canApply: function canApply() {
      return this.course.blocks && this.course.blocks.apply;
    },
    coursePageLink: function coursePageLink() {
      return "/learning/course/".concat(this.course.slug);
    },
    courseLandingLink: function courseLandingLink() {
      if (this.course.hasLanding) return "/learning/course/".concat(this.course.slug, "/about");
      return false;
    },
    defaultLink: function defaultLink() {
      if (['finished', 'joined'].includes(this.state)) {
        return this.coursePageLink;
      }
      if (!this.course.awaiting && this.defLink == 'page') {
        return this.coursePageLink;
      }
      return this.courseLandingLink || this.coursePageLink;
    },
    reportCardStore: function reportCardStore() {
      return useReportCardStore();
    },
    card: function card() {
      return this.reportCardStore.getCourseCard(this.course);
    },
    currentModule: function currentModule() {
      var _this$card;
      return ((_this$card = this.card) === null || _this$card === void 0 ? void 0 : _this$card.currentModule) || null;
    },
    currentModuleLink: function currentModuleLink() {
      var mod = this.currentModule;
      if (!mod) return null;
      return "/learning/course/".concat(this.course.slug, "/module/").concat(mod.slug);
    },
    progress: function progress() {
      if (!this.card) return 0;
      var passed = 0;
      var modulesLength = 0;
      var hiddenIds = [];
      this.course.program.forEach(function (o) {
        if (!o.hidden) modulesLength++;else hiddenIds.push(o.module);
      });
      if (this.card.modules) {
        this.card.modules.forEach(function (o) {
          if (o.passed && hiddenIds.indexOf(o.module) < 0) passed++;
        });
      }
      return Math.round(passed / modulesLength * 100);
    }
  },
  methods: {
    archive: function archive() {
      this.reportCardStore.setStatus({
        status: 'archived',
        id: this.card.id
      });
      this.$modalityStore.closeDropdown({
        name: "course-menu-".concat(this.course.id)
      });
    },
    restore: function restore() {
      this.reportCardStore.setStatus({
        status: 'active',
        id: this.card.id
      });
      this.$modalityStore.closeDropdown({
        name: "course-menu-".concat(this.course.id)
      });
    },
    levelColor: function levelColor(level) {
      if (level == 'beginner') return 'success';
      if (level == 'intermediate') return 'yellow';
      if (level == 'advanced') return 'purple';
      return 'error';
    }
  }
};